<template>
    <div class="search">
        <el-form :inline="true" :model="search" size="small" label-width="70px">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="按单位抓取" name="first">
                    <el-form-item label="任务分组">
                        <el-select v-model="search.taskGroup" placeholder="" style="width:100%">
                            <el-option label="储运销售公司" value="1"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="单位">
                        <el-input v-model="search.company" placeholder="请输入" clearable style="width:100%" />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" plain @click="getData">筛选</el-button>
                        <el-button type="" plain @click="reset">导入</el-button>
                    </el-form-item>
                </el-tab-pane>
                <el-tab-pane label="按人口学信息抓取" name="second">
                    <el-form-item label="性别">
                        <el-select v-model="search.tagGroup" placeholder="" style="width:100%">
                            <el-option label="全部" value="全部"></el-option>
                            <el-option label="男" value="男"></el-option>
                            <el-option label="女" value="女"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="年龄范围">
                        <div style="display: flex;">
                            <el-input v-model="search.age1"></el-input>
                            至
                            <el-input v-model="search.age2"></el-input>岁
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" plain @click="getData">筛选</el-button>
                        <el-button type="" plain @click="reset">导入</el-button>
                    </el-form-item>
                </el-tab-pane>
                <el-tab-pane label="按总检结论抓取" name="third">
                    <el-form-item label="报告筛选">
                        <el-select v-model="search.report" placeholder="" style="width:100%">
                            <el-option label="全部" value="1"></el-option>
                            <el-option label="2023年度" value="3"></el-option>
                            <el-option label="2022年度" value="2"></el-option>
                            <el-option label="2021年度" value="2021"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="建档时间">
                        <el-date-picker v-model="search.createdat" type="daterange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="档案类型">
                        <el-select v-model="search.type" placeholder="" style="width:100%">
                            <el-option label="全部" value="1"></el-option>
                            <el-option label="健康体检" value="2"></el-option>
                            <el-option label="职业体检" value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" plain @click="getData">筛选</el-button>
                        <el-button type="" plain @click="reset">导入</el-button>
                    </el-form-item>
                </el-tab-pane>
                <el-tab-pane label="按阳性指标抓取" name="fourth">
                    <el-form-item label="阳性指标">
                        <el-checkbox-group v-model="search.yangxing">
                            <el-checkbox label="血糖偏高" name="yangxing"></el-checkbox>
                            <el-checkbox label="高血脂" name="yangxing"></el-checkbox>
                            <el-checkbox label="高血压" name="yangxing"></el-checkbox>
                            <el-checkbox label="肥胖" name="yangxing"></el-checkbox>
                            <el-checkbox label="超重" name="yangxing"></el-checkbox>
                            <el-checkbox label="尿酸异常" name="yangxing"></el-checkbox>
                            <el-checkbox label="脂肪肝" name="yangxing"></el-checkbox>
                            <el-checkbox label="肺结节" name="yangxing"></el-checkbox>
                            <el-checkbox label="脑出血" name="yangxing"></el-checkbox>
                            <el-checkbox label="心动过速" name="yangxing"></el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" plain @click="getData">筛选</el-button>
                        <el-button type="" plain @click="reset">导入</el-button>
                    </el-form-item>
                </el-tab-pane>
            </el-tabs>
        </el-form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            search: { yangxing: [] },
            activeName: 'first'
        }
    }
}
</script>

<style lang="scss" scoped>
.search {
    padding: 10px;
    background-color: #FAFAFA;
    border-top: 1px solid #EBEEF5;
    border-left: 1px solid #EBEEF5;
    border-right: 1px solid #EBEEF5;

    ::v-deep .el-form-item {
        padding: 0 !important;
        margin-bottom: 0 !important;
    }
}
</style>
