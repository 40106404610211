<template>
    <div class="page">
        <div class="header">
            <div class="btns">
                <el-button size="mini" @click="getData" icon="el-icon-refresh">刷新</el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="数据抓取" />
        </div>
        <SearchForm></SearchForm>
        <div class="main-content">
            <div class="table-content">
                <el-table :data="table.data" border stripe size="mini" v-loading="loading"
                    width="100%" height="100%" :header-cell-style="{ backgroundColor: '#FAFAFA' }">
                    <el-table-column type="index" label="序号" align="center"
                        :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }" width="55" />
                    <el-table-column align="center" v-for="(v, idx) in table.columns" :prop="v.field" :label="v.title"
                        :width="v.width" :formatter="v.formatter" show-overflow-tooltip :key="idx">
                    </el-table-column>
                    <el-table-column align="center" width="200" label="操作" fixed="right">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" @click="handleRowChange(scope.row)">
                                处理
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                @current-change="handlePageChange" @size-change="handlePageSizeChange" />
        </div>
    </div>
</template>

<script>
import SearchForm from './widgets/searchForm.vue';
import data from './data'
export default {
    components: {
        SearchForm
    },
    data() {
        return {
            loading: false,
            table: data.table,
        }
    },
    methods: {
        handleRowChange(row) {
            this.$router.push({ path: `/main/phr/detail/${row.id}` })
        },
        handlePageChange(e) {
            this.table.page = e
            this.getData()
        },
        handlePageSizeChange(e) {
            this.table.pageSize = e
            this.getData()
        },
        async getData() {
            this.loading = true
            const resp = await this.$http.get(`/phr/check?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: this.search })
            if (!resp.data) {
                this.$message.error(resp.data.msg)
            } else {
                this.table.data = resp.data.data
                this.table.total = resp.data.total
            }
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.page {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.header {
    padding-bottom: 10px;

    .btns {
        float: right;
    }
}

.main-content {
    flex: 1;
    position: relative;
}

.table-content {
    position: absolute;
    width: 100%;
    height: 100%;
}

.pager {
    padding-top: 10px;
    text-align: center;
}
</style>
